// event.actions.ts

import { EventJoinerModel } from '@index/models/event-joiner';
import { createAction, props, union } from '@ngrx/store';
import { GthEventItemModel } from '@sentinels/models';

export enum EventActionTypes {
    EventInit = '[Event] Init',
    EventLoadOne = '[Event] Load One',
    EventLoadOneSuccess = '[Event] Load One Success',
    EventLoadOneError = '[Event] Load One Error',
    EventLoadListByUserId = '[Event] Load List By User Id',

    EventLoadSearchByStr = '[Event] Search By String',
    EventLoadListByLatLng = '[Event] Load List By Lat Lng',
    EventLoadListSuccess = '[Event] Load List Success',
    EventLoadListError = '[Event] Load List Error',
}

export const eventLoadOne = createAction(
    EventActionTypes.EventLoadOne,
    props<{ id: string }>(),
);

export const eventLoadOneSuccess = createAction(
    EventActionTypes.EventLoadOneSuccess,
    props<{ event: GthEventItemModel }>(),
);
export const eventLoadOneError = createAction(
    EventActionTypes.EventLoadOneError,
    props<{ error: unknown }>(),
);
export const eventLoadListByUserId = createAction(
    EventActionTypes.EventLoadListByUserId,
    props<{ id: string }>(),
);

export const eventLoadListByLatLng = createAction(
    EventActionTypes.EventLoadListByLatLng,
    props<{ lat: number, lng: number, bounds?: google.maps.LatLngBoundsLiteral, }>(),
);

export const eventSearchByStr = createAction(
    EventActionTypes.EventLoadSearchByStr,
    props<{ searchStr: string }>(),
);

export const eventLoadListSuccess = createAction(
    EventActionTypes.EventLoadListSuccess,
    props<{
        events: GthEventItemModel[],
        joiners: {[key:string]: EventJoinerModel[]}
    }>(),
);
export const eventLoadListError = createAction(
    EventActionTypes.EventLoadListError,
    props<{ error: unknown }>(),
);


export const allEvents = union({
    eventLoadOne,
    eventLoadOneSuccess,
    eventLoadOneError,
    eventLoadListByUserId,
    eventLoadListSuccess,
    eventLoadListError,
    eventLoadListByLatLng,
  });

export type EventActionsUnion = typeof allEvents;
